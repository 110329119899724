import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useConfig} from "../context/ConfigContext";

function Configuraciones(props) {

    // MODULOS DE CONFIGURACIONES DE NEGOCIO
    const { features } = useConfig();
    const ordersFeature = features.find(feature => feature.name === 'MODULO BASICO');

    // Obteniendo datos y funciones desde el contexto Config
    const {
        config = { deliveryCommon: { priceBase: 0, rangeMaxMeters: 0 } },
        toggleOrders,
        toggleDelivery,
        toggleVerifyAddress,
        changeDeliveryPrice,
        changeDeliveryRange,
        toggleActivePrice
    } = useConfig();

    const [priceBase, setPriceBase] = useState(config?.deliveryCommon?.priceBase || 0);
    const [rangeMaxMeters, setRangeMaxMeters] = useState(config?.deliveryCommon?.rangeMaxMeters || 0);

    useEffect(() => {

        document.title = 'QReate';

    }, [config]);


    return (
            <div className="containerConfig">
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleConfig">Configuraciones</h1>
                        </Col>
                    </Row>
                </Container>


                {ordersFeature && ordersFeature.status && (
                        <Container className="mt-3" style={{padding: '0px'}}>
                            <h3 className="titleRRSS">Configuraciones Pedidos Catálogo</h3>
                            <br/>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6">
                                    <h4 className="btnIsDelivery">Permitir Realizar Pedidos</h4>
                                    <input
                                        type="checkbox"
                                        id="isPedido"
                                        className="switch isDelivery"
                                        checked={config.status}
                                        onChange={(e) => toggleOrders(e.target.checked)}
                                    />
                                </Col>
                                <Col className="col-xl-6">
                                    <h4 className="buttonIsPriceActive">Visualizar Precios</h4>
                                    <input type="checkbox" className="switch isDelivery"
                                           checked={config.priceActive}
                                           onChange={(e) => toggleActivePrice(e.target.checked)}
                                           />
                                </Col>
                            </Row>

                            <h3 className="titleRRSS">Configuraciones Delivery</h3>
                            <br/>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                    <Col className="col-xl-6">
                                        <h4 className="btnIsDelivery">Permitir Delivery</h4>
                                        <input
                                            type="checkbox"
                                            className="switch isDelivery"
                                            checked={config.deliveryCommon.active}
                                            onChange={(e) => toggleDelivery(e.target.checked)}
                                        />
                                    </Col>
                                    <Col className="col-xl-6">
                                        <h4 className="btnIsValidDelivery">Validar Ubicaciones</h4>
                                        <input
                                            type="checkbox"
                                            className="switch isDelivery"
                                            checked={config.verifyAddress}
                                            onChange={(e) => toggleVerifyAddress(e.target.checked)}
                                        />
                                    </Col>
                            </Row>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                    <Col className="col-xl-6">
                                        <h4 className="btnIsDelivery">Precio del envío</h4>
                                        <input
                                            type="number"
                                            className="form-control priceDelivery w-25"
                                            value={priceBase}
                                            onChange={(e) => setPriceBase(e.target.value)} // Solo actualiza el valor local
                                        />
                                        <button
                                            className="btn btn-primary mt-2"
                                            onClick={() => changeDeliveryPrice(priceBase)} // Guarda al hacer clic
                                        >
                                            Guardar Precio
                                        </button>
                                    </Col>
                                <Col className="col-xl-6">
                                <h4 className="btnIsRange">Metros Max. Envío</h4>
                                    <input
                                        type="number"
                                        className="form-control priceDelivery w-25"
                                        value={rangeMaxMeters}
                                        onChange={(e) => setRangeMaxMeters(e.target.value)} // Solo actualiza el valor local
                                    />
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => changeDeliveryRange(rangeMaxMeters)} // Guarda al hacer clic
                                    >
                                        Guardar Metros
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    )
                }
            </div>
    );
}

export default Configuraciones;