import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

let stompClient = null;

export const connectWebSocket = (onConfigUpdate) => {

    // Conexión para el WebSocket
    const socket = new SockJS(`${process.env.REACT_APP_API_WHATSAPP_CONFIGS_SOCKET_ROUTE}`);
    stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
        console.log("Conectado al WebSocket");

        // Suscribe a configuraciones de delivery
        stompClient.subscribe("/topic/configDeliveryCommon", (message) => {
            const deliveryConfig = JSON.parse(message.body);
            //console.log("config Delivery: ", deliveryConfig);

            onConfigUpdate(prevConfig => ({
                ...prevConfig,
                deliveryCommon: deliveryConfig
            }));
        });

        // Suscribe a configuraciones de whatsapp
        stompClient.subscribe("/topic/configWhatsapp", (message) => {
            const config = JSON.parse(message.body);
            //console.log("config Whatsapp: ", config);
            onConfigUpdate(config);
        });

    });

};

// Función para enviar un mensaje al backend
export const sendConfigUpdateWhatsapp = (params) => {
    if (stompClient && stompClient.connected) {
        stompClient.send(
            `/app/updateConfigWhatsapp`, // Ruta que coincida con el @MessageMapping en el backend
            {}, // Headers opcionales
            JSON.stringify(params) // Cuerpo del mensaje
        );
    } else {
        console.error('WebSocket no está conectado');
    }
};

// Función para enviar un mensaje al backend
export const sendConfigUpdateDelivery = (params) => {
    if (stompClient && stompClient.connected) {
        stompClient.send(
            `/app/updateConfigDeliveryCommon`, // Ruta que coincida con el @MessageMapping en el backend
            {}, // Headers opcionales
            JSON.stringify(params) // Cuerpo del mensaje
        );
    } else {
        console.error('WebSocket no está conectado');
    }
};
