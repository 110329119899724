import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import HomeTitle from '../components/Home/HomeTitle';
import HomeNoShop from '../components/Home/HomeNoShop';
import HomeCardLink from '../components/Home/HomeCardLink';
import HomeQRButton from '../components/Home/HomeQRButton';
import HomeQRModal from '../components/Home/HomeQRModal';
import './HomePage.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useConfig} from "../context/ConfigContext";

export default function HomePage(){

    const navigate = useNavigate();
    
    const [isFillShopData, setIsFillShopData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [qr, setQr] = useState();

    const [shop, setShop] = useState();

    // MODULOS DE CONFIGURACIONES DE NEGOCIO
    const { features } = useConfig();
    const reportesFeature = features.find(feature => feature.name === 'REPORTES');


    useEffect(() => {

        document.title = 'QReate';

        axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1', {
            withCredentials: true
        })
        .then((response) => {
            setIsFillShopData(true);
            setShop(response.data);
        })
        .catch(() => {
            setIsFillShopData(false)
        })
        .finally(() => {
            setIsLoading(false)
        });

    }, []);

    const generateQR = () => {
        axios.get(process.env.REACT_APP_API_GENERATE_QR_ROUTE, {
            withCredentials: true,
            responseType: 'arraybuffer'
        })
        .then(response => {
            const blob = new Blob([response.data], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            // Decodificar la URL
            const decodedUrl = decodeURIComponent(imageUrl);
            setQr(decodedUrl);
        })
        .catch(error => {
            alertError();
            console.error(error)
        });
    }

    function alertError(){
        
        Swal.fire({
          title: 'Upsss',
          text: 'Ocurrió un error, vuelve a intentar',
          icon: 'error',
          timer: 1500,
          confirmButtonColor: 'rgb(246, 139, 127)',
          showConfirmButton: false,
        });

    }

    const download = () => {
        const url = qr;
        const a = document.createElement('a');
        a.href = url;
        a.download = 'qr_code.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    if (isLoading) {
        return <Loading/>; // Muestra un mensaje de carga mientras se realiza la petición
    }

    const handleRedirect = (url) => {
        navigate(url);
    }

    return (
      <div className='homeContainer'>
        <HomeTitle />
        {!isFillShopData ? (
          <HomeNoShop />
        ) : (
          <>
            <div className='cardListContainer'>
              <HomeCardLink to={'/carta'} title={'CARTA'} subtitle={'¡Aca podés ver tu carta lista!'} />
              <HomeQRButton action={generateQR} title={'QR'} subtitle={'Descargá el QR listo para usar'} />


              {reportesFeature && reportesFeature.status ? (
                <Container className="mt-3" style={{ padding: '0px' }}>
                  <Row>
                    <Col>
                      <Button
                        style={{ height: '160px', width: '100%', borderRadius: '20px', fontWeight: 'bold' }}
                        onClick={() => handleRedirect('/reporteVentas')}
                      >
                        Reporte de ventas
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ height: '160px', width: '100%', borderRadius: '20px', fontWeight: 'bold' }}
                        onClick={() => handleRedirect('/reporteProductos')}
                      >
                        Reporte de productos
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ height: '160px', width: '100%', borderRadius: '20px', fontWeight: 'bold' }}
                        onClick={() => handleRedirect('/reporteCategorias')}
                      >
                        Reporte de categorías
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ) : null}


            </div>
          </>
        )}
        <HomeQRModal qr={qr} download={download} />
      </div>
    );
}