import React, {useEffect, useState} from 'react';
import '../CartaOrderShop.css';
import iconoGenerico from '../iconoGenerico.svg';
import { RxCross2 } from "react-icons/rx";
import { RxCheck } from "react-icons/rx";
import iconoFooter from '../logoFooter.svg';
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import {
  AddAlt,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  Search,
  ShoppingCart,
  SubtractAlt,
  TrashCan
} from '@carbon/icons-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IoIosArrowDropleftCircle , IoIosArrowDroprightCircle  } from "react-icons/io";
import {useConfig} from "../context/ConfigContext";

export default function Carta() {

  const [categories, setCategories] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [shop, setShop] = useState();
  
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');

  const [imageSrc, setImageSrc] = useState('');
  const [colorshop, setColorShop] = useState('#000000');
  const [nameShop, setNameShop] = useState('');
  const [nameCategorySelected, setNameCategorySelected] = useState('');

  const [productsForCategory, setProductsForCategory] = useState([]);

  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCategoriesWithProducts, setSearchCategoriesWithProducts] = useState([]);
  const [searching, setSearching] = useState(false);

  const [allProducts, setAllProducts] = useState(true);
  const allProductsText = "TODOS LOS PRODUCTOS";

  const [dataProductsPedidoModal, setDataProductsPedidoModal] = useState([]);
  const [observationsPedido, setObservationsPedido] = useState('');

  //datos de envio
  const [nameAndSuername, setNameAndSuername] = useState('');
  const [isDelivery, setIsDelivery] = useState(false);
  const [stateAddress, setStateAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [floorAddress, setFloorAddress] = useState('');
  const [deptoAddress, setDeptoAddress] = useState('');
  const [observationsAddress, setObservationsAddress] = useState('');


  // Estados de validaciones activas/inactivas
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [lastValidatedAddress, setLastValidatedAddress] = useState({});
  const [localidadDirection, setLocalidadDirection] = useState('');

  //Estados config
  const {
    config
  } = useConfig();

  //Datos para rechargo o bonificacion
  const [isTax, setIsTax] = useState(false);
  const [typeTax, setTypeTax] = useState('');
  const [typeTaxValue, setTypeTaxValue] = useState('');
  const [valueTax, setValueTax] = useState();

  const createTax = () => {
    setIsTax(true);
    console.log({
      isTax,
      typeTax,
      typeTaxValue,
      valueTax
    });
  }

  const deleteTax = () => {
    setIsTax(false);
    console.log({
      isTax,
      typeTax,
      typeTaxValue,
      valueTax
    });
  }

  // Detecta si la dirección ha cambiado después de la validación
  const checkAddressChange = () => {
    const hasChanged =
        streetAddress !== lastValidatedAddress.streetAddress ||
        numberAddress !== lastValidatedAddress.numberAddress ||
        localidadDirection !== lastValidatedAddress.localidadDirection;

    if (hasChanged) {
      setIsAddressValid(false);
    }
  };

  const SendOrderType = {
    A_DOMICILIO: { label: "A domicilio", value: 1 },
    RETIRO_LOCAL: { label: "Retira en local", value: 2 }
  };
  const OrderType = {
    WHATSAP: { label: "WhatsApp", value: 1 },
    EN_MESA: { label: "En Mesa", value: 2 },
    DELIVERY: { label: "Delivery", value: 3 },
    A_DISTANCIA: { label: "A Distancia", value: 4 },
    MOSTRADOR: { label: "Mostrador", value: 5 }
  };

  useEffect(() => {
    document.title = 'QReate - Mostrador';
    if(!localStorage.getItem("productsPedido")){
      const listadoVacio = [];
      const listadoVacioJSON = JSON.stringify(listadoVacio);
      localStorage.setItem("productsPedido", listadoVacioJSON);
    }
    getAllCategories();
    getShop();
    getRedes();
    getAllProvinces();

  }, [config]);


  const getAllProvinces = () => {
    axios.get(process.env.REACT_APP_API_PROVINCES_ROUTE, {
      withCredentials: true
    })
    .then(response => {
        setProvinces(response.data);
      })
      .catch(error => {
        console.error(error)});
  }

  const getAllCategories = () => {
    axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE, {
      withCredentials: true
    })
    .then(response => {
        setCategories(response.data);
        setNameCategorySelected(allProductsText);
      })
      .catch(error => {
        console.error(error)});
  }

  const getShop = () => {
    axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1', {
      withCredentials: true
    })
    .then(response => {
        const shopData = response.data;
        if(shopData.image != null){
          const arrayBuffer = Uint8Array.from(atob(shopData.image), c => c.charCodeAt(0));
          const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Cambia 'image/jpeg' al tipo MIME correcto según el tipo de imagen que recibas
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
        } else {
          setImageSrc(null);
        }
        setShop(shopData);
        setColorShop(shopData.principalColorShop);
        setNameShop(shopData.name);
      })
      .catch(error => {
        console.error(error)});
  }

  const getRedes = () => {
    axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
      withCredentials: true,
        params: {
            status: true,
        }
      })
      .then(response => {
          getValueRedInstagram(response.data);
          getValueRedFacebook(response.data);
          getValueRedTwitter(response.data);
      })
  }

  const getValueRedInstagram = (redes) => {
    let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
    if(instagram != null){
        setInstagram(instagram.socialMediaLink);
    }
  }

  const getValueRedFacebook = (redes) =>{
      let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
      if(facebook != null){
          setFacebook(facebook.socialMediaLink);
      }
  }

  const getValueRedTwitter = (redes) =>{
      let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
      if(twitter != null){
          setTwitter(twitter.socialMediaLink);
      }
  }

  const fillProductsByIdCategorySelected = (idCategory) => {
      setProductsForCategory('');
      document.getElementById('nameProdSearch').value = "";
      search('');
      const categoria = categories.find(categoria => categoria.id === idCategory);
      setProductsForCategory(categoria ? categoria.productDTOList : []);
  }

  const fillAllProducts = () => {
    setProductsForCategory('');
    document.getElementById('nameProdSearch').value = "";
    search('');
  }

const filtrarCategoriasPorProducto = (texto) => {
  const categoriasFiltradas = [];

  categories.forEach(categoria => {
      const productosFiltrados = categoria.productDTOList.filter(producto => {
          return producto.name.toLowerCase().includes(texto.toLowerCase());
      });

      if (productosFiltrados.length > 0) {
          const categoriaFiltrada = {
              ...categoria,
              productDTOList: productosFiltrados
          };
          categoriasFiltradas.push(categoriaFiltrada);
      }
  });

  console.log("filtrado:");
  console.log(categoriasFiltradas);
  return categoriasFiltradas;
}

const search = (nameProd) => {
  if(nameProd != ''){
    setSearching(true);
    if(allProducts){
      setSearchCategoriesWithProducts(filtrarCategoriasPorProducto(nameProd));
    } else {
      const productsFilters = productsForCategory.filter(product =>
        product.name.toLowerCase().includes(nameProd.toLowerCase()));
      setSearchProducts(productsFilters);
    }
  }else{
    setSearching(false);
    setSearchProducts('');
    setSearchCategoriesWithProducts('');
  }
}

function formatPrice(price) {
  if (!price) return ''; // Manejar caso de valor vacío
  const parts = price.toString().split('.'); // Separar parte entera de la decimal
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
  return parts.join(','); // Unir las partes con coma como separador decimal
}

  const addProdPedido = (newProd) => {
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === newProd.id) == -1;
      if(notExistProduct){
        let newProduct = {
          id: newProd.id,
          name: newProd.name,
          description: newProd.description,
          price: newProd.price,
          cant: 1
        }
        productsPedido.push(newProduct);
      }else{
        const indiceProducto = productsPedido.findIndex(product => product.id === newProd.id);
        productsPedido[indiceProducto].cant += 1;
      }
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
      document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value + 1;
  }

  const minusProdPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
      if(!notExistProduct){
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        if(productsPedido[indiceProducto].cant > 1){
          productsPedido[indiceProducto].cant -= 1;
        }else{
          productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
        }
        console.log("guardar");
        localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
        setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
        document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value - 1;
      }
    }
  }

  const deleteProductPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
    }
  }

  const contProd = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      if(productsPedido != null){
        const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
        if(notExistProduct){
          return "0";
        }
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        return productsPedido[indiceProducto].cant.toString();
      }
      return "0";
    }
    return "0";
  }

  const calcularTotal = (productos) => {
    let total = 0;
  
    for (let i = 0; i < productos.length; i++) {
      const producto = productos[i];
      const subtotal = producto.price * producto.cant;
      total += subtotal;
    }
  
    return total;
  }

  const cleanPedidoBorrador = () => {
    Swal.fire({
      title: '¿Estás seguro de borrar el pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cleanPedido();
      } else {
        const botonCarrito = document.getElementById('btnCarrito');
        botonCarrito.click();
      }
    });
  }

  const cleanPedido = () => {
    document.getElementById('cantEnPedido').value = "0";
    const listadoVacio = [];
    const listadoVacioJSON = JSON.stringify(listadoVacio);
    localStorage.setItem("productsPedido", listadoVacioJSON);
    window.location.reload();
  }

  // Función reutilizable para mostrar errores de campo
  const showFieldError = (selectorError, selectorInput) => {
    const fieldRequiredElement = document.querySelector(selectorError);
    const inputElement = document.getElementById(selectorInput);
    if (fieldRequiredElement && inputElement) {
      fieldRequiredElement.style.display = 'block';
      inputElement.style.border = '1px solid #ff1717';
    }
  };

  const sendOrderForShop = () => {
    const isNameInvalid = !nameAndSuername?.trim();

    const isDeliveryInvalid = isDelivery && (
        !streetAddress?.trim() ||
        !numberAddress?.trim() ||
        !localidadDirection?.trim()
    );

    if (isNameInvalid || isDeliveryInvalid) {
      if (isNameInvalid) {
        showFieldError('.errorNameAndSurname', 'nameAndSurname');
      }

      if (isDelivery) {
        if (!streetAddress?.trim()) {
          showFieldError('.errorStreetAddress', 'streetAddress');
        }
        if (!numberAddress?.trim()) {
          showFieldError('.errorNumberAddress', 'numberAddress');
        }
        if (!localidadDirection?.trim()) {
          showFieldError('.errorLocalidadDirection', 'localidadDirection');
        }
      }
      return;  // Salir si hay errores de validación
    }

    // Continuar con el flujo de envío
    if (config.verifyAddress && !isAddressValid) {
      showAlert({
        title: 'Error de dirección',
        text: 'La dirección ha cambiado o no ha sido validada. Por favor, validá la dirección antes de continuar.',
        icon: 'error',
      });
      return;  // Salir si la dirección no es válida
    }

    confirmarEnvioPedido();
  };
  const confirmarEnvioPedido = () => {
    Swal.fire({
      title: '¿Enviar pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (!config.status) {
          Swal.fire({
            title: 'Por el momento ya no tomamos más pedidos',
            text: "Gracias por tu comprensión, ¡te esperamos la próxima!",
            icon: 'error',
            showConfirmButton: true,
            allowOutsideClick: false,
            confirmButtonColor: colorshop,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const btnCloseModalCarrito = document.getElementById('btnCloseCarrito');
              btnCloseModalCarrito.click();
              const btnCloseModalDatosPersona = document.getElementById('btnCloseModalPersona');
              btnCloseModalDatosPersona.click();
            }
          });
        } else {
          let orderNumber = Date.now();
          sendOrder(orderNumber);
        }
      }
    });
  };

  const sendOrder = (orderNumber) => {
    let paymentAdjustmentDTO = {}
    if(isTax){
      paymentAdjustmentDTO = {
        id: null,
        orderId: null,
        adjustmentType: typeTax === "Descuento" ? "DISCOUNT" : typeTax === "Recargo" ? "SURCHARGE" : null,
        valueType: typeTaxValue === "%" ? "PERCENTAGE" : typeTaxValue === "$" ? "FIXED_AMOUNT" : null,
        value: valueTax,
      }
    }
    console.log("paymentAdjustmentDTO")
    console.log(paymentAdjustmentDTO)

    let order = {
      id: null,
      orderNumber: orderNumber,
      ownerOrder: nameAndSuername,
      totalAmount: 0,
      tableId: 1, //setear numero de mesa que selecciono el usuario
      orderType: OrderType.MOSTRADOR.value,
      sendOrderType: (isDelivery && config.deliveryCommon.active ) ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
      address: streetAddress,
      numberAdress: numberAddress,
      floor: floorAddress,
      department: deptoAddress,
      provinceId: 20, //ver como mando el id de la provincia
      orderDetails: [],
      orderObservation: observationsPedido,
      deliveryObservation: observationsAddress,
      paymentAdjustmentDTO: paymentAdjustmentDTO,
    }

    let products = JSON.parse(localStorage.getItem("productsPedido"));
    products.forEach((product) => {
      order.totalAmount += (product.cant * product.price);
      order.orderDetails.push(createOrderDetail(product));
    });

    order.totalAmount = order.totalAmount.toFixed(2);
    
    axios.post(process.env.REACT_APP_API_ORDER_ROUTE, order, {
      withCredentials: true
    })
    .then(response => {
      Swal.fire({
        title: 'Pedido realizado con éxito',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,  // El SweetAlert se cerrará automáticamente después de 2 segundos
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then(() => {
          cleanPedido();
      });
      })
      .catch(error => {
        console.log(error);
        console.log(error.response.data.errorMsg);
        
        Swal.fire({
          title: 'Error con el pedido:',
          text: error.response.data.errorMsg,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      });
  }

  const createOrderDetail = (product) => {
    return {
      id: null,
      orderId: null,
      productId: product.id,
      quantity: product.cant,
      unitaryPrice: Number(product.price),
      subTotal: Number(product.cant * product.price)
    };
  }

  const cleanErrorNameAndSurname = () => {
    if (nameAndSuername != '' && nameAndSuername != null) {
      let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('nameAndSurname');
      inputElement.style.border = '';
    }
  }

  const cleanErrorStateAddress = () => {
    if (stateAddress != '' && stateAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorStateAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('stateAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorStreetAddress = () => {
    if (streetAddress != '' && streetAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorStreetAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('streetAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorNumberAddress = () => {
    if (numberAddress != '' && numberAddress != null) {
      let fieldRequiredElement = document.querySelector('.errorNumberAddress');
      fieldRequiredElement.style.display = 'none';
      let inputElement = document.getElementById('numberAddress');
      inputElement.style.border = '';
    }
  }

  const cleanErrorSendPedido = () => {
    let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
    if (fieldRequiredElement) {
        fieldRequiredElement.style.display = 'none';
    }
    
    let inputElement = document.getElementById('nameAndSurname');
    if (inputElement) {
        inputElement.style.border = '';
    }

    setNameAndSuername('');

    if (isDelivery) {
        fieldRequiredElement = document.querySelector('.errorAddressPedido');
        if (fieldRequiredElement) {
            fieldRequiredElement.style.display = 'none';
        }
        
        inputElement = document.getElementById('addressPedido');
        if (inputElement) {
            inputElement.style.border = '';
        }
    }
    setStateAddress('');
    setStreetAddress('');
    setNumberAddress('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('')
    setIsDelivery(false);
}

  function scrollLeft() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: -200,
      behavior: 'smooth'
    });
  }

  function scrollRight() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: 200,
      behavior: 'smooth'
    });
  }

  // Funciones sobre taxes
  const calculateSubtotal = (typeTax, typeTaxValue, valueTax) => {
    const total = calcularTotal(dataProductsPedidoModal);
    if (typeTax === "Descuento") {
      if (typeTaxValue === "%") {
        return ((total * (1 - valueTax / 100)) - total).toFixed(2);
      } else if (typeTaxValue === "$") {
        return ((total - parseFloat(valueTax)) - total).toFixed(2);
      }
    } else if (typeTax === "Recargo") {
      if (typeTaxValue === "%") {
        return ((total * (1 + valueTax / 100)) - total).toFixed(2);
      } else if (typeTaxValue === "$") {
        return ((total + parseFloat(valueTax)) - total).toFixed(2);
      }
    }
    return total.toFixed(2);  // Valor por defecto si no coincide ninguna condición
  };

  const calculateTotalWithTax = (typeTax, typeTaxValue, valueTax) => {
    const total = calcularTotal(dataProductsPedidoModal);
    if (typeTax === "Descuento") {
      if (typeTaxValue === "%") {
        return (total * (1 - valueTax / 100)).toFixed(2);
      } else if (typeTaxValue === "$") {
        return (total - parseFloat(valueTax)).toFixed(2);
      }
    } else if (typeTax === "Recargo") {
      if (typeTaxValue === "%") {
        return (total * (1 + valueTax / 100)).toFixed(2);
      } else if (typeTaxValue === "$") {
        return (total + parseFloat(valueTax)).toFixed(2);
      }
    }
    return total.toFixed(2);  // Valor por defecto si no coincide ninguna condición
  };

  const deleteTaxAndResetInputs = () => {
    deleteTax(); // Llama a tu función para eliminar el tax.

    // Limpia los valores de los inputs
    setTypeTax("");
    setTypeTaxValue("");
    setValueTax("");
  };

  const showAlert = ({ title, text, icon, confirmButton = true }) => {
    Swal.fire({
      title,
      text,
      icon,
      showConfirmButton: confirmButton,
      confirmButtonColor: colorshop,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const validateAddress = () => {
    if (!streetAddress || !numberAddress || !localidadDirection) {
      showAlert({title: 'Campos faltantes:', text: 'Por favor, completa todos los campos de la dirección antes de validar.', icon: 'warning',});
      return;
    }

    return new Promise((resolve) => {
      const localId = 1;
      const keyAddressValidate = process.env.REACT_APP_API_KEY_ADDRESS_VALIDATE;

      const address = `${streetAddress} ${numberAddress}`;
      const encodedAddress = encodeURIComponent(address);
      const encodedLocality = encodeURIComponent(localidadDirection);
      const baseUrl = process.env.REACT_APP_API_LOCALIZATION_VALIDATE_ROUTE;

      fetch(`${baseUrl}${localId}?address=${encodedAddress}&locality=${encodedLocality}`, {
        method: 'GET',
        withCredentials: true,
      })
          .then((response) => {
            if (response.ok) {
              return response.text(); // Si es OK, devuelve el texto
            } else {
              return response.text().then((text) => {
                showAlert({title: 'Error de validación:', text, icon: 'error',});
                setIsAddressValid(false);
              });
            }
          })
          .then((data) => {
            if (data) {
              showAlert({title: 'Dirección validada', text: 'La dirección ha sido validada correctamente.', icon: 'success',});
              resolve(data); // Resolución de la promesa si la validación es exitosa
              setIsAddressValid(true);
              setLastValidatedAddress({
                streetAddress,
                numberAddress,
                localidadDirection,
              });
            }
          })
          .catch((error) => {
            showAlert({
              title: 'Error de conexión:',
              text: 'Hubo un problema al validar la dirección.',
              icon: 'error',
            });
            setIsAddressValid(false);
          });
    });
  };

    return (
      <div className="carta-body-mostrador">
        <div className="categories-outer-container">
          <button className="scroll-button left" onClick={scrollLeft}><IoIosArrowDropleftCircle size={30}/></button>
          <div className="categories-inner-container">
            <div className="categories-mostrador">
              <div className="category" style={{marginLeft: '2%'}}>
                <button className={`round-button ${allProducts ? 'selectedCategory' : ''}`} onClick={() => {fillAllProducts(); setAllProducts(true); setNameCategorySelected("Todos los productos");}} style={{backgroundColor: colorshop}}>
                  <img src={iconoGenerico} alt="Icono" />
                </button>
                <div className="category-name">Todos los productos</div>
              </div>
              {categories.map(category => (
                  <div className="category" key={category.id}>
                    <button className={`round-button ${category.name === nameCategorySelected ? 'selectedCategory' : ''}`} onClick={() => {fillProductsByIdCategorySelected(category.id); setNameCategorySelected(category.name); setAllProducts(false)}} style={{backgroundColor: colorshop}}>
                      <img src={iconoGenerico} alt="Icono" />
                    </button>
                    <div className="category-name">{category.name}</div>
                  </div>
              ))}
            </div>
          </div>
          <button className="scroll-button right" onClick={scrollRight}><IoIosArrowDroprightCircle size={30}/></button>
        </div>


        <div className='margin5'>
            <div className="searchProduct">
              <div className="search">
                <input type="text" id='nameProdSearch' className="searchProdCarta" placeholder="Buscar producto" style={{width : "100%"}} onChange={(e) => search(e.target.value)}/>
                <Search className="btnSearchCarta" size="35"/>
              </div>
            </div>

            <div className="products" style={{minHeight: '40vh'}}>
              {allProducts ? (
                (searching) ? (
                  <>
                    <h6 className='titleCategory'>{allProductsText}</h6>
                    <br/>
                    {searchCategoriesWithProducts.map(category => (
                      <>
                        <h6 className='titleCategory'>{category.name}</h6>
                        {category.productDTOList.map(product => (
                          <div className='product'>
                            <p className='nameProd'>{product.name}</p>
                            <p className='descProd'>{product.description}</p>
                              <div className='divPriceAndBotonera'>
                                <p className='priceProd'>${formatPrice(product.price)}</p>
                                    <div className='divBotonera'>
                                      <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                      <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                      <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                                    </div>
                              </div>
                          </div>
                        ))}
                        <br/>
                      </>
                    ))}
                    
                  </>
                ) : (
                  <>
                  <h6 className='titleCategory'>{allProductsText}</h6>
                  <br/>
                  {categories.map(category => (
                    <>
                      <h6 className='titleCategory'>{category.name}</h6>
                      {category.productDTOList.map(product => (
                        <div className='product'>
                          <p className='nameProd'>{product.name}</p>
                          <p className='descProd'>{product.description}</p>
                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                                  <div className='divBotonera'>
                                    <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                    <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                    <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                                  </div>
                            </div>
                        </div>
                      ))}
                      <br/>
                    </>
                  ))}
                  
                </>
                )
                
              ) : (
                <>
                <h6 className='titleCategory'>{nameCategorySelected}</h6>
                { (searching) ? (
                  searchProducts.map(product => (
                    <div className='product'>
                          <p className='nameProd'>{product.name}</p>
                          <p className='descProd'>{product.description}</p>

                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                              <div className='divBotonera'>
                                <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                              </div>
                            </div>

                        </div>
                  ))
                ) : (
                  productsForCategory.map(product => (
                    <div className='product'>
                    <p className='nameProd'>{product.name}</p>
                    <p className='descProd'>{product.description}</p>

                            <div className='divPriceAndBotonera'>
                              <p className='priceProd'>${formatPrice(product.price)}</p>
                              <div className='divBotonera'>
                                <button className='btn'><SubtractAlt size={25} onClick={() => minusProdPedido(product.id)}/></button>
                                <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                <button className='btn'><AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => addProdPedido(product)}/></button>
                              </div>
                            </div>

                  </div>
                )))
                }
                </>
              )}
            </div>
          </div>

          {(localStorage.getItem("productsPedido") != '[]') && (
            <button id='btnCarrito' data-bs-toggle="modal" data-bs-target="#modalProductsPedido" className="round-button-pedido-mostrador"
              onClick={() => {setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")))}}>
              <ShoppingCart size={30}/>
            </button>
          )}

          <div class="modal fade modal-lg" id="modalProductsPedido" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalProductsPedidoLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header modalPedido">
                  <h1 class="modal-title fs-5" id="modalProductsPedidoLabel"
                  className="titleModalPedido" style={{marginTop: '4%', marginLeft: '4%'}}>Pedido</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btnCloseCarrito"
                          onClick={() => {setTypeTax(''); setTypeTaxValue(''); setValueTax('');}}></button>
                </div>
                <div class="modal-body modalPedido">

                  {dataProductsPedidoModal.map(product => (
                      <div className='productCarrito'>
                        <p className='nameProdPedido'>{product.name}</p>
                        <p classname=''>{product.description}</p>

                        <div className='divPriceAndBotoneraPedido'>
                          <p className='priceProdPedido'>${formatPrice(product.price)}</p>
                          <div className='divBotonera'>
                            <button className='btn' style={{color: 'red'}}><TrashCan size={22}
                                                                                     onClick={() => deleteProductPedido(product.id)}/>
                            </button>
                            <button className='btn'><SubtractAlt size={22} onClick={() => minusProdPedido(product.id)}/>
                            </button>
                            <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                            <button className='btn'><AddAlt size={22} style={{marginLeft: '5%'}}
                                                            onClick={() => addProdPedido(product)}/></button>
                          </div>
                        </div>
                        <p className='priceProdPedidoSubtotal'>Subtotal:
                          ${formatPrice((product.price * product.cant).toFixed(2))}</p>
                      </div>
                  ))}
                  <br/>
                  {/* Inputs en la misma línea */}
                  <h1 className="modal-title fs-5" className="titleConfPago" style={{marginTop: '1%', marginLeft: '4%'}}>Configuracion de pago:</h1>
                  <div className="d-flex align-items-center divRechargesAndBonifications">
                    <div className="inputGroup me-2 divTipo">
                    <label htmlFor="tipo" className="col-form-label label">Tipo:</label>
                      <div>
                        <div className="radioItem">
                          <input
                              type="radio"
                              id="recargo"
                              name="tipo"
                              value="Recargo"
                              checked={typeTax === "Recargo"}
                              onChange={(e) => setTypeTax(e.target.value)}
                          />
                          <label htmlFor="recargo">Recargo</label>
                        </div>

                        <div className="radioItem">
                          <input
                              type="radio"
                              id="descuento"
                              name="tipo"
                              value="Descuento"
                              checked={typeTax === "Descuento"}
                              onChange={(e) => setTypeTax(e.target.value)}
                          />
                          <label htmlFor="descuento">Descuento</label>
                        </div>
                      </div>
                    </div>

                    <div className="inputGroup me-2 divTipoValor">
                      <label htmlFor="tipoValor" className="col-form-label label">Tipo de Valor:</label>
                      <div>
                        <div className="radioItem">
                          <input
                              type="radio"
                              id="porcentaje"
                              name="tipoValor"
                              value="%"
                              checked={typeTaxValue === "%"}
                              onChange={(e) => setTypeTaxValue(e.target.value)}
                          />
                          <label htmlFor="porcentaje">Porcentaje %</label>
                        </div>

                        <div className="radioItem">
                          <input
                              type="radio"
                              id="valorFijo"
                              name="tipoValor"
                              value="$"
                              checked={typeTaxValue === "$"}
                              onChange={(e) => setTypeTaxValue(e.target.value)}
                          />
                          <label htmlFor="valorFijo">Valor Fijo $</label>
                        </div>
                      </div>
                    </div>

                    <div className="inputGroup divMonto">
                      <label htmlFor="monto" className="col-form-label label">Cantidad:</label>
                      <input
                          type="number"
                          id="monto"
                          className="form-control"
                          value={valueTax}
                          onChange={(e) => setValueTax(e.target.value)}
                      />
                    </div>

                    <div className="divCheckTax">
                      {isTax ? (
                          <Button className="btn btnCheckTaxDelete" onClick={deleteTaxAndResetInputs}>
                            <RxCross2 size={30} style={{color: "white"}}/>
                          </Button>
                      ) : (
                          <Button
                              className="btn btnCheckTaxCreate"
                              onClick={createTax}
                              disabled={!typeTax || !typeTaxValue || !valueTax} // Deshabilitar si falta algún campo
                          >
                            <RxCheck size={30} style={{color: "white"}}/>
                          </Button>
                      )}
                    </div>
                  </div>

                  {isTax ? (
                      <>
                        <br/>
                        <hr/>
                        <p className="priceProdTotalPedido">
                          Subtotal:
                          ${formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}
                        </p>
                        <p className="priceProdTotalPedido">
                          {typeTax} ({typeTaxValue}{valueTax}):
                          ${formatPrice(calculateSubtotal(typeTax, typeTaxValue, valueTax))}
                        </p>
                        <p className="priceProdTotalPedido" style={{fontWeight: 'bold', fontSize: '1.2em'}}>
                          Total: ${formatPrice(calculateTotalWithTax(typeTax, typeTaxValue, valueTax))}
                        </p>
                        <hr/>
                      </>
                  ) : (
                      <>
                        <br/>
                        <hr/>
                        <p className="priceProdTotalPedido" style={{fontWeight: 'bold', fontSize: '1.2em'}}>
                          Total: ${formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}
                        </p>
                        <hr/>
                      </>
                  )}

                  <br/>
                  <div style={{marginLeft: '5%'}}>
                    <label for="observations" class="col-form-label">Obvservaciones del pedido:</label>
                    <textarea rows="3" className="form-control observationsPedido" id="observationsPedido"
                              alue={observationsPedido} onChange={(e) => {
                      setObservationsPedido(e.target.value);
                    }}/>
                  </div>
                </div>
                <div class="modal-footer modalPedido">
                  <button id="btnCloseModalAdd" type="button" class="btn btn-dark" data-bs-dismiss="modal"
                          onClick={() => cleanPedidoBorrador()}>Borrar pedido
                  </button>
                  <button id="btnSendPedidoForWhatsapp" type="button" class="btn"
                          data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalDataForPedido"
                          style={{backgroundColor: colorshop, color: '#ffffff'}}
                          disabled={dataProductsPedidoModal.length == 0 ? true : false}>Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>

        <div class="modal fade modal-lg" id="modalDataForPedido" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="modalDataForPedidoLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header modalPedido">
                <h1 class="modal-title fs-5" id="modalDataForPedidoLabel" className="titleModalDatosPedido">Datos del
                  cliente</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                        onClick={() => cleanErrorSendPedido()}></button>
                <button id="btnCloseModalPersona" hidden={true} type="button" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => cleanErrorSendPedido()}></button>
              </div>
              <div class="modal-body modalPedido">

                <label for="nameAndSurname" class="col-form-label">Nombre y apellido:</label>
                <input type="text" className="form-control nameAndSuername" id="nameAndSurname"
                       value={nameAndSuername} onChange={(e) => {
                  setNameAndSuername(e.target.value);
                  cleanErrorNameAndSurname();
                }}
                       onBlur={() => cleanErrorNameAndSurname()}/>
                <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                <label for="isDelivery" class="col-form-label">Envío a domicilio:</label>
                  {(config.deliveryCommon.active) ? <>
                    <br/>
                    <div className="form-check form-check-inline">
                      <input
                          className="form-check-input"
                          type="radio"
                          id="isDeliveryTrue"
                          name="isDelivery"
                          value="true"
                          checked={isDelivery === true}
                          onChange={() => setIsDelivery(true)}
                      />
                      <label className="form-check-label" htmlFor="isDeliveryTrue">
                        Sí
                      </label>
                    </div>
                  <br/>
                  <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="isDeliveryFalse"
                        name="isDelivery"
                        value="false"
                        checked={isDelivery === false}
                        onChange={() => {
                          setIsDelivery(false);
                          setStateAddress('');
                          setStreetAddress('');
                          setNumberAddress('');
                          setFloorAddress('');
                          setDeptoAddress('');
                          setObservationsAddress('')
                        }}
                    />
                    <label className="form-check-label" htmlFor="isDeliveryFalse">
                      No
                    </label>
                  </div>
                </> : <>
                <p style={{color: '#CB3234'}}>Por el momento no realizamos envíos, ¡retiralo en nuestro local!</p>
                  <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="isDeliveryTrue"
                        name="isDelivery"
                        value="false"
                        disabled={true}
                    />
                    <label className="form-check-label" htmlFor="isDeliveryTrue">
                      Sí
                    </label>
                  </div>
                  <br/>
                  <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        id="isDeliveryFalse"
                        name="isDelivery"
                        value="true"
                        disabled={true}
                    />
                    <label className="form-check-label" htmlFor="isDeliveryFalse">
                      No
                    </label>
                  </div>
                </>}


                {isDelivery &&
                    <>
                      <br/>
                      <p style={{color: "#959595"}}>Valor del envío:
                        ${formatPrice(config.deliveryCommon.priceBase || 0)}</p>
                      <label for="addressPedido" className="col-form-label">Dirección de entrega:</label>
                      <br/>

                      {/*
                    <label for="stateAddress" className="col-form-label">Provincia:</label>
                    <select
                      className="form-control stateAddress" id="stateAddress"
                      value={stateAddress}
                      onChange={(e) => {setStateAddress(e.target.value); cleanErrorStateAddress();}}
                      onBlur={() => cleanErrorStateAddress()}
                    >
                      <option value=''>Seleccione provincia</option>
                      {provinces
                            .map(province => (
                              <option value={province.id}>{province.name}</option>
                          ))}
                    </select>
                    <div className='errorStateAddress' id='errorStateAddress'>Campo obligatorio</div>
                    */}

                      <div className='row'>
                        <div className='col-8'>
                          <label htmlFor="streetAddress" className="col-form-label">Calle:</label>
                          <input
                              type="text"
                              className="form-control streetAddress"
                              id="streetAddress"
                              value={streetAddress}
                              onChange={(e) => {
                                setStreetAddress(e.target.value);
                                checkAddressChange();
                              }}
                              disabled={!config.deliveryCommon.active}
                          />
                          <div className='errorStreetAddress' id='errorStreetAddress'>Campo obligatorio</div>
                        </div>

                        <div className='col'>
                          <label htmlFor="numberDirection" className="col-form-label">Numero:</label>
                          <input
                              type="number"
                              className="form-control numberAddress"
                              id="numberAddress"
                              value={numberAddress}
                              onChange={(e) => {
                                setNumberAddress(e.target.value);
                                checkAddressChange();
                              }}
                              disabled={!config.deliveryCommon.active}
                          />
                          <div className='errorNumberAddress' id='errorNumberAddress'>Campo obligatorio</div>
                        </div>

                      </div>

                      <div className='row'>
                        <div className='col-8'>
                          <label htmlFor="localidadDirection" className="col-form-label">Localidad:</label>
                          <input
                              type="text"
                              className="form-control localidadDirection"
                              id="localidadDirection"
                              value={localidadDirection}
                              onChange={(e) => {
                                setLocalidadDirection(e.target.value);
                                checkAddressChange();
                              }}
                              disabled={!config.deliveryCommon.active}
                          />
                          <div className='errorLocalidadDirection' id='errorLocalidadDirection'>Campo obligatorio
                          </div>
                        </div>
                        {config.verifyAddress && (
                            <div className='col'>
                              <label htmlFor="validarDireccion" className="col-form-label"></label> <br/><br/>
                              <button type="button" className="btn"
                                      style={{backgroundColor: '#007bff', color: '#ffffff'}}
                                      onClick={() => validateAddress()}>Validar Dirección
                              </button>
                            </div>
                        )}
                      </div>

                      <div className='row'>
                        <div className='col'>
                          <label for="piso" class="col-form-label">Piso:</label>
                          <input type="number" className="form-control piso" id="piso"
                                 value={!config.deliveryCommon.active ? "" : floorAddress} onChange={(e) => {
                            setFloorAddress(e.target.value);
                          }} disabled={!config.deliveryCommon.active}/>
                        </div>

                        <div className='col'>
                          <label for="depto" class="col-form-label">Depto:</label>
                          <input type="text" className="form-control depto" id="depto"
                                 value={!config.deliveryCommon.active ? "" : deptoAddress} onChange={(e) => {
                            setDeptoAddress(e.target.value);
                          }} disabled={!config.deliveryCommon.active}/>
                        </div>
                      </div>
                      <br/>

                      <label for="observations" class="col-form-label">Indicacion adicional del destino:</label>
                      <textarea placeholder='Casa con rejas negras, sin timbre' rows="3"
                                className="form-control observations" id="observations"
                                value={!config.deliveryCommon.active ? "" : observationsAddress} onChange={(e) => {
                        setObservationsAddress(e.target.value);
                      }} disabled={!config.deliveryCommon.active}/>
                    </>
                }
              </div>

              <div class="modal-footer modalPedido">
                <button id="btnCloseModalSendPedido" type="button" class="btn btn-secondary"
                        data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                        onClick={() => cleanErrorSendPedido()}>Volver
                </button>
                <button type="button" className="btn"
                        style={{backgroundColor: colorshop, color: '#ffffff'}}
                        onClick={() => sendOrderForShop()}>Finalizar pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}