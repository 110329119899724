import React, { createContext, useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
    connectWebSocket,
    sendConfigUpdateDelivery,
    sendConfigUpdateWhatsapp
} from '../config/WebsocketService';

const ConfigContext = createContext();


const ConfigProvider = ({ children }) => {
  const keyLocalStorage = 'authToken';

  const [config, setConfig] = useState({
                                                      "id": 1,
                                                      "orderStartTime": null,
                                                      "orderEndTime": null,
                                                      "deliveryType": "COMMON",
                                                      "idDelivery": 1,
                                                      "shopId": 1,
                                                      "status": false,
                                                      "nameFrontFilter": "isOrderActive",
                                                      "verifyAddress": false,
                                                      "deliveryCommon": {
                                                          "id": 1,
                                                          "priceBase": "00",
                                                          "rangeMaxMeters": 10,
                                                          "nameFrontFilter": "isDelivery",
                                                          "active": false
                                                      },
                                                      "priceActive": true
                                                  });

  const [features, setFeatures] = useState([]);


    /******************************************************
            METODOS GENERICOS DE CONFIGURACIONES
     ******************************************************/

    // Método para obtener las flag de modulos NEGOCIO (visualiza reportes - stock , etc )
    const fetchFeatures = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_FEATURE_SELECTOR_ROUTE}`, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            });
            setFeatures(response.data);
        } catch (error) {
            console.error('Error al obtener las características', error);
        }
    };

    //Metodo para obtener toda la config de whatsap y delivery
    const fetchConfigs = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_MODULES_CONFIGS_ROUTE, {
        withCredentials: true
      });
      if (response.data) {
        setConfig(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
      connectWebSocket((newConfig) => {
          setConfig(newConfig);
      });

     fetchConfigs();
     fetchFeatures();
  }, []);



    /******************************************************
            METODOS DE CONFIGURACIONES DE WHATSAPP
     ******************************************************/

   // Método que se llama cuando se cambia la configuracion de pedido activo
    const toggleOrders = (isChecked) => {
        const updatedConfig = {
            ...config,
            status: isChecked,
        };

        // Si los pedidos están activos y la visualización de precios no, activa la visualización de precios
        if (isChecked && config.priceActive === false) {
            showErrorMessage('Se tienen que activar la visualizacion de precios.');
        } else {
            // Enviar la actualización al WebSocket
            sendConfigUpdateWhatsapp(updatedConfig);
            setConfig(updatedConfig);
            showSuccessMessage('Estado pedidos actualizado.');
        }
    };

    // Método que se llama cuando se cambia la configuracion de visualizacion de precios
    const toggleActivePrice = (newActivePrice) => {
        const updatedConfig = {
            ...config,
            priceActive: newActivePrice,
        };

        // Si los pedidos están activos y la visualización de precios no, activa la visualización de precios
        if (newActivePrice === false && config.status === true) {
                showErrorMessage('Se tiene que desactivar los pedidos.');
        } else {
            // Enviar la actualización al WebSocket
            sendConfigUpdateWhatsapp(updatedConfig);
            setConfig(updatedConfig);
            showSuccessMessage('Estado visualizacion precios actualizado.');
        }
    };

    // Método que se llama cuando se cambia la configuracion de verificacion de ubicacion
    const toggleVerifyAddress = (isChecked) => {
        // Si los pedidos estan inactivos no se puede activar la verificacion de ubicacion
        if(isChecked && config.deliveryCommon.active === false){
            showErrorMessage('Se tienen que activar el delivery.');
        } else {
            const updatedConfig = {
                ...config,
                verifyAddress: isChecked,
            };
            // Enviar la actualización al WebSocket
            sendConfigUpdateWhatsapp(updatedConfig);
            setConfig(updatedConfig);
            showSuccessMessage('Estado verificacion ubicaciones actualizado.');
        }
    };


    /******************************************************
            METODOS DE CONFIGURACIONES DE DELIVERY
     ******************************************************/

    // Método que se llama cuando se cambia la configuracion de status de delivery
    const toggleDelivery = (isChecked) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                active: isChecked,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Estado delivery actualizado.');

        // Si los pedidos están activos y la visualización de precios no, activa la visualización de precios
        if (!isChecked && config.verifyAddress) {
            toggleVerifyAddress(false);
        }

    };

    // Método que se llama cuando se cambia la configuracion de precio de delivery
    const changeDeliveryPrice = (newDeliveryPrice) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                priceBase: newDeliveryPrice,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Precio delivery actualizado.');
    };

    // Método que se llama cuando se cambia la configuracion de distancia de delivery
    const changeDeliveryRange = (newDeliveryRange) => {
        const updatedConfig = {
            ...config,
            deliveryCommon: {
                ...config.deliveryCommon,
                rangeMaxMeters: newDeliveryRange,
            },
        };

        // Enviar la actualización al WebSocket
        sendConfigUpdateDelivery(updatedConfig.deliveryCommon);
        setConfig(updatedConfig);
        showSuccessMessage('Rango delivery actualizado.');
    };

    const showSuccessMessage = (message) => {
        Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: message,
            timer: 2000,
            showConfirmButton: false,
        });
    };

    const showErrorMessage = (message) => {
        Swal.fire({
            icon: 'error',
            title: '¡Atención!',
            text: message,
            timer: 2000,
            showConfirmButton: false,
        });
    };


    return (
    <ConfigContext.Provider value={{
      config,
      toggleOrders,
      toggleDelivery,
      toggleVerifyAddress,
      changeDeliveryPrice,
      changeDeliveryRange,
      features,
      toggleActivePrice
    }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext };

export function useConfig() {
  return useContext(ConfigContext);
}