import React from 'react';
import './Menu.css';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {IoReloadOutline} from "react-icons/io5";
import axios from 'axios';
import Swal from 'sweetalert2';

const MenuRechargeCartaButton = ({ isOpen }) => {

  const rechargeDataCarta = () => {
    Swal.fire({
      title: '¿Desea refrescar los datos de la carta?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Refrescar',
      confirmButtonColor: 'rgb(246, 139, 127)',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("result.isConfirmed -->", result.isConfirmed);
        console.log("process -->", process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE)
        axios.delete(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE, {
          withCredentials: true
        })
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Datos actualizados.',
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error al realizar la solicitud PUT', error);
          });
      }
    });
  }

  return (
    <div className='menu-item-container'>
      <Nav.Link as={Link} onClick={rechargeDataCarta}>
        <div>
          <IoReloadOutline className="icon" />
        </div>
        <div className='span-container'>
          <span className={isOpen ? "text" : "text hidden"}>Refrescar</span>
        </div>
      </Nav.Link>
    </div>
  )
}

export default MenuRechargeCartaButton;